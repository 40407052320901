import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/Home.css';

const Home = () => {
  return (
    <div className="home">
      <h1>HeightVerify AI</h1>
      <p>
      Ever get the vibe that some dudes might be adding a few extra inches to their height on dating apps? 
      Let AI spill the tea! Just upload a couple pics, and we’ll do the measuring for you.
      </p>
      <div className="cta-buttons">
        <Link to="/register" className="cta-button">Sign Up</Link>
        <Link to="/estimate" className="cta-button">Try It Now</Link>
      </div>
      <div className="features">
        <h2>How It Works</h2>
        <ul>
          <li>Upload 3-4 images of the person in question</li>
          <li>Our AI analyzes the photos</li>
          <li>Find out if their height matches what they claim!</li>
        </ul>
      </div>
    </div>
  );
};

export default Home;
