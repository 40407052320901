// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import PrivateRoute from './components/PrivateRoute';
import Dashboard from './components/Dashboard';
import Login from './components/Login';
import Register from './components/Register';
import HeightEstimator from './components/HeightEstimator';
import Pricing from './components/Pricing';
import SuccessPage from './components/SuccessPage';
import Home from './components/Home';  // Import Home component
import Navbar from './components/Navbar';

function App() {
  return (
    <AuthProvider>
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />  {/* Set Home component for the root path */}
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
          <Route path="/estimate" element={<PrivateRoute><HeightEstimator /></PrivateRoute>} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/success" element={<PrivateRoute><SuccessPage /></PrivateRoute>} />
          <Route path="*" element={<Navigate to="/" />} />  {/* Catch-all route, redirect to home */}
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
