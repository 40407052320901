import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { functions } from '../firebase';
import { httpsCallable } from 'firebase/functions';
import { loadStripe } from '@stripe/stripe-js';
import '../styles/Pricing.css';

const stripePromise = loadStripe('pk_live_51Ps366AmUSGxaqh95dJH3LPIzB3OyahLZjeee0qcpIIvHnpmqcpXmWrH2Udn8dQylfHp8LSVCwBIJGzGpBobxsdW000M7uT19h');

const Pricing = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  const handleSubscription = async (plan) => {
    if (!currentUser) {
      alert('Please log in to subscribe.');
      return;
    }

    try {
      const createCheckoutSession = httpsCallable(functions, 'createCheckoutSession');
      const { data } = await createCheckoutSession({ plan });
      
      const stripe = await stripePromise;
      const { error } = await stripe.redirectToCheckout({ sessionId: data.sessionId });
      
      if (error) {
        throw new Error(error.message);
      }
    } catch (error) {
      console.error('Error:', error);
      alert(`An error occurred: ${error.message}`);
    }
  };

  return (
    <div className="pricing">
      <h2>Choose Your Plan</h2>
      <div className="plans">
        <div className="plan basic">
          <h3>Basic</h3>
          <p>3 estimations per day</p>
          <p>Free</p>
        </div>
        <div className="plan premium">
          <h3>Premium</h3>
          <p>7 estimations per day</p>
          <p>$4.99/month</p>
          <button onClick={() => handleSubscription('premium')}>Select</button>
        </div>
        <div className="plan pro">
          <h3>Pro</h3>
          <p>Unlimited estimations</p>
          <p>$7.99/month</p>
          <button onClick={() => handleSubscription('pro')}>Select</button>
        </div>
      </div>
    </div>
  );
};

export default Pricing;
