import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFunctions } from 'firebase/functions';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyD4_QRJrIjC3AJHRsZhsZPR2KjykMIAiJ4",
    authDomain: "height-check-ai.firebaseapp.com",
    projectId: "height-check-ai",
    storageBucket: "height-check-ai.appspot.com",
    messagingSenderId: "407553819870",
    appId: "1:407553819870:web:11640a5f5de8c1258fee4f",
    measurementId: "G-C9FP2LTR9B"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Get Firebase services
const auth = getAuth(app);
const functions = getFunctions(app);
const db = getFirestore(app);
const storage = getStorage(app);

export { auth, functions, db, storage };

