import React, { createContext, useContext, useState, useEffect } from 'react';
import { auth, db, functions } from '../firebase';
import { 
  createUserWithEmailAndPassword, 
  signInWithEmailAndPassword, 
  signOut, 
  onAuthStateChanged 
} from 'firebase/auth';
import { doc, setDoc, getDoc, onSnapshot } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);

  // This function handles user signup
  async function signup(email, password) {
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      const userData = {
        email: user.email,
        plan: 'basic',
        dailyEstimations: {
          count: 0,
          date: new Date().toISOString().split('T')[0],
        },
        estimationsRemaining: 3,  // Default to 3 for basic users
        createdAt: new Date(),
      };

      // Debugging log to ensure correct initial values
      console.log("Before Firestore write: Setting user data to Firestore:", userData);

      // Write user data to Firestore
      await setDoc(doc(db, 'users', user.uid), userData);

      // Correct Firestore fetch after writing
      const userDocRef = doc(db, 'users', user.uid);
      const userDoc = await getDoc(userDocRef);
      console.log("After Firestore write: User document data in Firestore:", userDoc.data());

      return user;
    } catch (error) {
      console.error("Error in signup:", error);
      throw error;
    }
  }

  // Log in the user
  function login(email, password) {
    return signInWithEmailAndPassword(auth, email, password);
  }

  // Log out the user
  function logout() {
    return signOut(auth);
  }

  // Upgrade the user's plan and update their estimations accordingly
  async function upgradePlan(newPlan) {
    if (!currentUser) throw new Error('No user logged in');

    try {
      const updateUserPlan = httpsCallable(functions, 'updateUserPlan');
      await updateUserPlan({ plan: newPlan });
      console.log("Plan upgrade initiated");
    } catch (error) {
      console.error("Error upgrading plan:", error);
      throw error;
    }
  }

  // Function to refresh user data from Firestore
  async function refreshUserData() {
    if (currentUser) {
      const userRef = doc(db, 'users', currentUser.uid);
      const unsubscribe = onSnapshot(userRef, (doc) => {
        if (doc.exists()) {
          console.log("User data refreshed:", doc.data());
          setCurrentUser(prevUser => ({ ...prevUser, ...doc.data() }));
        }
      });
      return unsubscribe;
    }
  }

  // This useEffect listens for authentication state changes
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        const userRef = doc(db, 'users', user.uid);

        // Listen for changes to the user's document
        const unsubscribeDoc = onSnapshot(userRef, (doc) => {
          if (doc.exists()) {
            console.log("User logged in and data fetched:", doc.data());

            // Prevent overwriting of data and ensure estimationsRemaining is set correctly
            setCurrentUser({ ...user, ...doc.data() });
          } else {
            // If document doesn't exist, set only user auth data
            setCurrentUser(user);
          }
          setLoading(false);
        }, (error) => {
          console.error("Error fetching user data:", error);
          setLoading(false);
        });

        return () => unsubscribeDoc();
      } else {
        // User is not logged in, reset currentUser
        setCurrentUser(null);
        setLoading(false);
      }
    });

    return unsubscribe;
  }, []);

  // Provide all auth context values to the rest of the app
  const value = {
    currentUser,
    signup,
    login,
    logout,
    upgradePlan,
    refreshUserData
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}

export default AuthProvider;
