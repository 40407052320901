import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

const SuccessPage = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { upgradePlan, refreshUserData, currentUser } = useAuth();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const sessionId = queryParams.get('session_id');
    const plan = queryParams.get('plan');

    if (!sessionId || !plan) {
      setError('Invalid session or plan');
      setLoading(false);
      return;
    }

    const updateUserPlan = async () => {
      try {
        console.log('Upgrading plan to:', plan);
        await upgradePlan(plan);
        console.log('Plan upgraded, refreshing user data');
        await refreshUserData();
        console.log('User data refreshed, new plan:', currentUser?.plan);
        setSuccess(true);
        setLoading(false);
        setTimeout(() => navigate('/dashboard'), 5000);
      } catch (error) {
        console.error('Error updating plan:', error);
        setError('Failed to update plan. Please contact support.');
        setLoading(false);
      }
    };

    if (currentUser) {
      updateUserPlan();
    } else {
      setError('User not authenticated. Please log in and try again.');
      setLoading(false);
    }
  }, [location.search, navigate, upgradePlan, refreshUserData, currentUser]);

  useEffect(() => {
    if (success) {
      console.log('Success state set, current user plan:', currentUser?.plan);
    }
  }, [success, currentUser]);

  if (loading) {
    return (
      <div className="success-page">
        <h2>Processing Your Payment</h2>
        <p>Please wait while we update your plan...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="success-page error">
        <h2>Error</h2>
        <p>{error}</p>
        <button onClick={() => navigate('/dashboard')}>Return to Dashboard</button>
      </div>
    );
  }

  if (success) {
    return (
      <div className="success-page">
        <h1>Payment Successful!</h1>
        <p>Your plan has been upgraded. You will be redirected to the dashboard shortly.</p>
        <p>New Plan: {currentUser?.plan || 'Loading...'}</p>
        <button onClick={() => navigate('/dashboard')}>Go to Dashboard Now</button>
      </div>
    );
  }

  return null;
};

export default SuccessPage;