// Dashboard.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import '../styles/Dashboard.css';

const Dashboard = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  const getPlanDetails = (plan) => {
    switch (plan) {
      case 'pro':
        return { limit: 'Unlimited' };
      case 'premium':
        return { limit: 7 };
      default: // basic plan
        return { limit: 3 };
    }
  };

  const planDetails = getPlanDetails(currentUser?.plan || 'basic');

  const handleUpgrade = () => {
    navigate('/pricing');
  };

  const handleEstimate = () => {
    navigate('/estimate');
  };

  if (!currentUser) {
    return <div>Loading...</div>;
  }

  return (
    <div className="dashboard">
      <h1>Your Dashboard</h1>
      <div className="dashboard-content">
        <div className="user-info">
          <p>Email: <strong>{currentUser.email}</strong></p>
          <p>Current Plan: <strong>{currentUser.plan || 'Basic'}</strong></p>
          <p>Estimations Today: <strong>{currentUser.dailyEstimations?.count || 0}/{planDetails.limit}</strong></p>
        </div>

        {/* Display upgrade message if the user hits their daily limit */}
        {currentUser.dailyEstimations?.count >= planDetails.limit && (
          <div className="upgrade-message">
            <p>You have reached your daily estimation limit. Consider upgrading to get more estimations.</p>
            <button onClick={handleUpgrade} className="upgrade-button">Upgrade Your Plan</button>
          </div>
        )}

        {/* Only show upgrade button for non-pro users who haven’t reached the limit */}
        {currentUser.plan !== 'pro' && currentUser.dailyEstimations?.count < planDetails.limit && (
          <button onClick={handleUpgrade} className="upgrade-button">Upgrade Your Plan</button>
        )}

        {/* Disable the "Estimate Height" button if the user has hit the limit */}
        <button
          onClick={handleEstimate}
          className="estimate-button"
          disabled={currentUser.dailyEstimations?.count >= planDetails.limit}
        >
          Estimate Height
        </button>
      </div>
    </div>
  );
};

export default Dashboard;
