import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { functions, storage, db } from '../firebase';
import { httpsCallable } from 'firebase/functions';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { doc, onSnapshot } from 'firebase/firestore';
import '../styles/HeightEstimator.css';

const HeightEstimator = () => {
  const [files, setFiles] = useState([]);
  const [estimatedHeight, setEstimatedHeight] = useState(null);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState(null);
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  
  // Create a reference to the file input element
  const fileInputRef = useRef(null);

  useEffect(() => {
    if (currentUser) {
      const userRef = doc(db, 'users', currentUser.uid);
      const unsubscribe = onSnapshot(userRef, (doc) => {
        if (doc.exists()) {
          setUserData(doc.data());
        }
      });

      return () => unsubscribe();
    }
  }, [currentUser]);

  const getPlanDetails = (plan) => {
    if (!userData) return { limit: 'Loading...', remaining: 'Loading...' };
  
    switch (plan) {
      case 'pro':
        return { limit: 'Unlimited', remaining: 'Unlimited' };
      case 'premium':
        return { limit: 7, remaining: userData.estimationsRemaining };
      default: // basic plan
        return { limit: 3, remaining: userData.estimationsRemaining };
    }
  };

  const planDetails = getPlanDetails(userData?.plan || 'basic');

  const handleFileChange = (e) => {
    if (e.target.files.length > 4) {
      setError('You can only upload up to 4 images');
      return;
    }
    setFiles(Array.from(e.target.files));
    setError('');
  };

  // Function to trigger the file input click when clicking the upload area
  const handleUploadAreaClick = () => {
    fileInputRef.current.click();
  };

  async function handleEstimate(e) {
    e.preventDefault();
    if (!currentUser) {
      navigate('/login');
      return;
    }

    if (files.length === 0) {
      setError('Please select at least one image to upload');
      return;
    }

    if (planDetails.remaining !== 'Unlimited' && planDetails.remaining <= 0) {
      setError('You have reached your daily estimation limit. Please upgrade your plan or try again tomorrow.');
      return;
    }

    setLoading(true);
    setError('');

    try {
      const uploadedUrls = await Promise.all(
        files.map(async (file) => {
          const storageRef = ref(storage, `images/${currentUser.uid}/${file.name}`);
          await uploadBytes(storageRef, file);
          return getDownloadURL(storageRef);
        })
      );

      const estimateHeight = httpsCallable(functions, 'estimateHeight');
      const result = await estimateHeight({ imageUrls: uploadedUrls });
      
      setEstimatedHeight(result.data);
    } catch (error) {
      console.error('Error:', error);
      setError('Failed to estimate height: ' + (error.message || 'Unknown error'));
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="height-estimator">
      <h2>Height Estimation</h2>
      <p>Current Plan: {userData?.plan || 'Basic'}</p>
      {error && <p className="error">{error}</p>}
      <form onSubmit={handleEstimate}>
        <div className="upload-area" onClick={handleUploadAreaClick}>
          <input
            type="file"
            ref={fileInputRef} // Attach the file input reference
            multiple
            accept="image/*"
            onChange={handleFileChange}
            style={{ display: 'none' }} // Hide the file input
          />
          <p>Upload 3-4 images for best results</p>
        </div>
        {files.length > 0 && (
          <div className="image-preview">
            {files.map((file, index) => (
              <img key={index} src={URL.createObjectURL(file)} alt={`Preview ${index + 1}`} />
            ))}
          </div>
        )}
        <button 
          type="submit" 
          disabled={loading || files.length === 0 || (planDetails.remaining !== 'Unlimited' && planDetails.remaining <= 0)}
        >
          {loading ? 'Processing...' : 'Estimate Height'}
        </button>
      </form>
      {estimatedHeight && (
        <div className="estimation-result">
          <h3>Estimated Height:</h3>
          <p className="highlight">{estimatedHeight.feetInches} ({estimatedHeight.estimatedHeight.toFixed(1)} cm)</p>
          <p className="range">Range: {estimatedHeight.range}</p>
        </div>
      )}
    </div>
  );
};

export default HeightEstimator;
